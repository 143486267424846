// @flow
import * as Sentry from '@sentry/browser';

const { NODE_ENV } = process.env;

const initializeSentry = () => {
  Sentry.init({
    release: 'training-ui@v1.19.10',
    environment: NODE_ENV,
    dsn: 'https://28f5fb305617490ba80a0bab4952b9a4@sentry.io/1536578'
  });
};

export default initializeSentry;
